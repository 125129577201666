var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"crearPedido",attrs:{"titulo":"Crear Pedido","tamano":"modal-lg","icon":"Filledoutline"},on:{"guardar":_vm.crear_pedido}},[_c('ValidationObserver',{ref:"form1"},[_c('div',{staticClass:"col-10 mx-auto justify-center"},[_c('ValidationProvider',{staticClass:"w-100 col-10",attrs:{"rules":"required","name":"Cliente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2"},[_vm._v("Cliente")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","filterable":"","placeholder":"Seleccionar"},on:{"change":_vm.set_cliente},model:{value:(_vm.model.cliente),callback:function ($$v) {_vm.$set(_vm.model, "cliente", $$v)},expression:"model.cliente"}},_vm._l((_vm.opciones),function(item){return _c('el-option',{key:item.id_user,attrs:{"label":item.nombre,"value":item.id_user}})}),1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 mx-auto justify-center"},[_c('p',{staticClass:"ml-2"},[_vm._v("Direccion")]),_c('ValidationProvider',{staticClass:"w-100 col-10",attrs:{"rules":"required","name":"direccion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-middle"},[_c('el-select',{staticClass:"w-100",attrs:{"size":"small","filterable":"","placeholder":"Seleccionar"},on:{"change":_vm.set_direccion},model:{value:(_vm.value_direccion),callback:function ($$v) {_vm.value_direccion=$$v},expression:"value_direccion"}},_vm._l((_vm.direcciones),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.direccion,"value":item.id}})}),1),_c('el-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.cliente),expression:"model.cliente"}],attrs:{"content":"Buscar en el Mapa","placement":"top","effect":"light"}},[_c('i',{staticClass:"icon-location f-20 cr-pointer ml-2",on:{"click":_vm.open_mapa}})])],1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 pl-5  mx-auto d-middle"},[_c('i',{staticClass:"icon-arrow-top-right text-gris f-20"}),_c('p',{staticClass:"ml-2 f-14 f-600"},[_vm._v(_vm._s(_vm.redondearNumero(_vm.model.distancia,1))+" Metros Aprox.")])]),_c('div',{staticClass:"row f-14 mt-4 mx-0 justify-center"},[_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"ml-2"},[_vm._v("Fecha de Entrega")]),_c('ValidationProvider',{staticClass:"w-100 col-10",attrs:{"rules":"required","name":"fecha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{attrs:{"type":"date","size":"small","placeholder":"Fecha","value-format":"yyyy-MM-dd","value":"yyyy-MM-dd","clearable":false,"picker-options":_vm.pickerOptions},on:{"change":_vm.set_horario},model:{value:(_vm.model.entrega_fecha),callback:function ($$v) {_vm.$set(_vm.model, "entrega_fecha", $$v)},expression:"model.entrega_fecha"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"ml-2"},[_vm._v("Hora de Entrega")]),_c('ValidationProvider',{staticClass:"w-100 col-10",attrs:{"rules":"required","name":"hora"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{attrs:{"size":"small","placeholder":"Horario"},model:{value:(_vm.model.entrega_horario),callback:function ($$v) {_vm.$set(_vm.model, "entrega_horario", $$v)},expression:"model.entrega_horario"}},_vm._l((_vm.horarios),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value,"disabled":item.disabled}})}),1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row f-14 mt-4 mx-0 justify-center"},[_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"ml-2"},[_vm._v("Valor domicilio")]),_c('div',{staticClass:"d-middle"},[_c('span',{staticClass:"border br-10 p-1 w-100"},[_vm._v(_vm._s(_vm.separadorNumero(_vm.model.domicilio,_vm.$tienda.decimales)))])])]),_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"ml-2"},[_vm._v("Paga con billete de:")]),_c('div',{staticClass:"d-middle"},[_c('money',_vm._b({staticClass:"input-money",model:{value:(_vm.model.billete),callback:function ($$v) {_vm.$set(_vm.model, "billete", $$v)},expression:"model.billete"}},'money',_vm.money,false))],1)])]),_c('div',{staticClass:"col-10  mx-auto my-3"},[_c('p',{staticClass:"ml-2"},[_vm._v("Instrucciones")]),_c('el-input',{attrs:{"type":"textarea","rows":"4","placeholder":"Digite la instrucciones de entrega","maxlength":"1000","show-word-limit":""},model:{value:(_vm.model.instrucciones),callback:function ($$v) {_vm.$set(_vm.model, "instrucciones", $$v)},expression:"model.instrucciones"}})],1)]),_c('mapaGoogleModal',{ref:"modalMapa",attrs:{"busqueda":"","postal":"","dragable":"","icono-central":"/img/icons/map-marker-plus.svg","titulo":"Localice al Cliente"},on:{"manual":_vm.datos_mapa}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }