<template>
    <modal ref="crearPedido" v-loading="loading" titulo="Crear Pedido" tamano="modal-lg" icon="Filledoutline" @guardar="crear_pedido">
        <ValidationObserver ref="form1">
            <div class="col-10 mx-auto justify-center">
                <ValidationProvider v-slot="{errors}" class="w-100 col-10" rules="required" name="Cliente">
                    <p class="ml-2">Cliente</p>
                    <el-select v-model="model.cliente" class="w-100" size="small" filterable placeholder="Seleccionar" @change="set_cliente">
                        <el-option
                        v-for="item in opciones"
                        :key="item.id_user"
                        :label="item.nombre"
                        :value="item.id_user"
                        />
                    </el-select>
                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                </ValidationProvider>
            </div>
            <div class="col-10 mx-auto justify-center">
                <p class="ml-2">Direccion</p>
                <ValidationProvider v-slot="{errors}" class="w-100 col-10" rules="required" name="direccion">
                    <div class="d-middle">
                        <el-select v-model="value_direccion" class="w-100" size="small" filterable placeholder="Seleccionar" @change="set_direccion">
                            <el-option
                            v-for="item in direcciones"
                            :key="item.id"
                            :label="item.direccion"
                            :value="item.id"
                            />
                        </el-select>
                        <el-tooltip v-show="model.cliente" content="Buscar en el Mapa" placement="top" effect="light">
                            <i class="icon-location f-20 cr-pointer ml-2" @click="open_mapa" />
                        </el-tooltip>
                    </div>
                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                </ValidationProvider>
            </div>
            <div class="col-10 pl-5  mx-auto d-middle">
                <i class="icon-arrow-top-right text-gris f-20" />
                <p class="ml-2 f-14 f-600">{{ redondearNumero(model.distancia,1) }}  Metros Aprox.</p>
            </div>

            <div class="row f-14 mt-4 mx-0 justify-center">
                <div class="col-4">
                    <p class="ml-2">Fecha de Entrega</p>
                    <ValidationProvider v-slot="{errors}" class="w-100 col-10" rules="required" name="fecha">
                        <el-date-picker
                        v-model="model.entrega_fecha"
                        type="date"
                        size="small"
                        placeholder="Fecha"
                        value-format="yyyy-MM-dd"
                        value="yyyy-MM-dd"
                        :clearable="false"
                        :picker-options="pickerOptions"
                        @change="set_horario"
                        />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <p class="ml-2">Hora de Entrega</p>
                    <ValidationProvider v-slot="{errors}" class="w-100 col-10" rules="required" name="hora">
                        <el-select v-model="model.entrega_horario" size="small" placeholder="Horario">
                            <el-option
                            v-for="item in horarios"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled"
                            />
                        </el-select>
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row f-14 mt-4 mx-0 justify-center">
                <div class="col-4">
                    <p class="ml-2">Valor domicilio</p>
                    <div class="d-middle">
                        <span class="border br-10 p-1 w-100">{{ separadorNumero(model.domicilio,$tienda.decimales) }}</span>
                    </div>
                </div>
                <div class="col-3">
                    <p class="ml-2">Paga con billete de:</p>
                    <div class="d-middle">
                        <money v-model="model.billete" class="input-money" v-bind="money" />
                    </div>
                </div>
            </div>
            <div class="col-10  mx-auto my-3">
                <p class="ml-2">Instrucciones</p>
                <el-input
                v-model="model.instrucciones"
                type="textarea"
                rows="4"
                placeholder="Digite la instrucciones de entrega"
                maxlength="1000"
                show-word-limit
                />
            </div>
        </ValidationObserver>
        <mapaGoogleModal
        ref="modalMapa"
        busqueda
        postal
        dragable
        icono-central="/img/icons/map-marker-plus.svg"
        titulo="Localice al Cliente"
        @manual="datos_mapa"
        />
    </modal>
</template>

<script>
import {Money} from 'v-money'
import {mapGetters} from 'vuex'
import moment from 'moment'
import Pedidos from '~/services/pedidos/pedidos-tienda'
export default {
    components:{
        Money,
    },
    data(){
        let that = this;
        return{
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < that.fechaMinima
                }
            },
            fechaMinima:moment(),
            fecha_valida:moment(),
            hora_base:0,
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            value_direccion:'',
            loading:false,
            opciones:[],
            horarios:[],
            direcciones:[],
            cliente:{},
            model:{
                cliente:'',
                billete:0,
                direccion:'',
                longitud:0,
                latitud:0,
                distancia:0,
                domicilio:0,
                instrucciones:'',
                entrega_fecha:'',
                entrega_horario:''
            },
        }
    },
    methods: {
        open_mapa(){
            this.$refs.modalMapa.toggle()
        },
        datos_mapa(e){
            // console.log(e,'daysdaudau');

            this.direcciones.push({
                id:0,
                direccion:e.direccion,
                longitud:e.lng,
                latitud:e.lat
            })
            this.value_direccion = 0
            this.set_direccion(0)

        },
        toggle(){
            this.model.cliente = ''
            this.model.billete = 0
            this.model.instrucciones = ''
            this.model.entrega_fecha = '',
            this.model.entrega_horario = ''
            this.cliente = {}
            this.money.precision = this.$tienda.decimales
            this.select_clientes()
            this.set_franja()
            this.$refs.crearPedido.toggle()
        },
        async select_clientes(){
            const {data} = await Pedidos.select_clientes()
            this.opciones = data.clientes
        },
        async crear_pedido(){
            try {
                if(this.model.billete > 16000000){
                    this.notificacion('Advertencia','El valor maximo para billete es de $16 Millones','warning')
                    return
                }
                this.loading = true
                const valid = await this.$refs.form1.validate()
                if(valid){
                    const {data} = await Pedidos.crear_pedido(this.model)
                    this.notificacion('Éxito','Pedido almacenado correctamente','success')
                    this.$router.push({params:{id_pedido:data.data.id}})
                    this.$refs.crearPedido.toggle()
                }
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        set_cliente(id){
            this.cliente = this.opciones.find(o=>o.id_user === id)
            this.value_direccion = ''
            this.direcciones = [],
            this.model.direccion = ''
            this.model.longitud = 0
            this.model.latitud = 0
            this.model.distancia = 0
            this.model.domicilio = 0
            this.get_direcciones(id)
        },
        async get_direcciones(id_user){
            const {data} = await Pedidos.get_direcciones(id_user)
            this.direcciones = [...data.direcciones]

            if(data.length){
                this.model.direccion = data.direcciones[0].direccion
            }
        },
        set_direccion(id){
            let direccion = this.direcciones.find(o=>o.id === id)
            this.model.direccion = direccion.direccion
            this.model.longitud = direccion.longitud
            this.model.latitud = direccion.latitud

            this.set_distancia()
        },
        async set_distancia(){
            let model = {
                longitud:this.model.longitud,
                latitud:this.model.latitud
            }
            const {data} = await Pedidos.get_distancia(model)
            this.model.distancia = data.distancia

            this.set_domicilio()
        },
        async set_domicilio(){
            let distancia = this.model.distancia
            const {data} = await Pedidos.get_valor_domicilio(distancia)
            this.model.domicilio = data
        },
        async set_franja(){
            try {
                const {data} = await Pedidos.get_horarios()
                data.horarios.map(h=>{
                    h.disabled = false
                })
                this.horarios = data.horarios

                this.fecha_valida = moment().add(data.tiempo_horas,'hours');
                this.hora_base =  this.fecha_valida.hour()
                let f1 = moment().add(data.tiempo_horas,'hours');

                let ultima_franja = _.cloneDeep(this.horarios).pop().value
                if(this.hora_base > ultima_franja){
                    this.fechaMinima = f1
                }else{
                    this.fechaMinima = f1.subtract(1,'days')
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        set_horario(fecha){
            let hoy = this.fecha_valida.format('YYYY-MM-DD')
            let hora = this.hora_base

            if(hoy === fecha){
                this.horarios.map(h=>{
                    if(h.value < hora){
                        h.disabled=true
                    }
                })

            }else{
                this.horarios.map(h=>{
                    h.disabled=false
                })
            }

            this.model.entrega_horario = ''
        }
    }
}
</script>

<style lang="css" scoped>
.card-prod{
    width: 330px;
    display: flex;
}
.text-aqua{
    color: #03D6BC;
}
</style>
